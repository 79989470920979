
.forum-content{
    padding-top:20rem;
    background-repeat: no-repeat;
    background-position-x: 12vw;
    background-position-y: 18vh;
    background-size: 50vh;
    overflow: auto;
    font-size: larger;
    font-family: Futura;
}

.forum-title-svg{
    margin-bottom: 5rem;
}

.forum-postbar{
    padding: 2rem 1.5rem 1.5rem 3rem !important;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    background-size: cover;
    vertical-align: middle;
}

.comment-text{
    padding: 2rem 5rem !important;
}

.post-divider{
    display: flex;
    height: 10px !important;
    width: 80vw;
    background-repeat: no-repeat;
    background-size: contain;
}

.level-2-comment{
    padding-left: 5rem !important;
}

@media screen and (max-width: 575px) {
    .forum-content {
        padding-top:1rem !important;
        background-image: none !important;
        font-size: medium;
    }

    .forum-title-svg{
        margin-bottom: 3rem;
    }

    .forum-postbar{
        padding-left: 2rem !important;
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: center;
        background-size: cover;
        font-size: large !important;
    }

    .comment-text{
        padding: 0.2rem 1.2rem !important;
        font-size: medium !important;
    }

    .post-divider{
        display: flex;
        height: 5px !important;
        width: 80vw;
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        margin: 1rem 3rem;
    }

    .level-2-comment{
        padding: 1rem 1.5rem 0rem 2rem !important;
    }

    
}