
.history-content{
    padding-top:20rem;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: contain;
    overflow: auto;
    font-size: larger;
    font-family: Futura;
}

.history-title-svg{
    margin-bottom: 5rem;
    
}

.historyadrienneandjemma{
    height: auto;
    width: 45vw;
    background-size: contain;
}

@media screen and (max-width: 575px) {
    .history-content {
        padding-top:1rem !important;
        background-image: none !important;
        font-size: medium;
    }

    .history-title-svg{
        margin-bottom: 3rem;
    }

    .historyadrienneandjemma{
        width: 100vw;
    }
}