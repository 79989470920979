
.home-content{
    padding-top:10rem;
}

.home-content-svg{
    height: 60%;
    width: 60%;
    margin: 20rem 0rem 10rem 0rem;
}

@media screen and (max-width: 575px) {
    .home-content-svg{
        height: 80%;
        width: 80%;
        margin: 5rem 0rem 5rem 0rem;
    }

    .home-content{
        padding-top:5rem;
    }
}