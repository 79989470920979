.hak-modal {
    position: absolute;
    top: 5%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    background-color: rgb(158, 165, 173);
    border-radius: 5px;
    border: 4px solid rgb(69, 76, 82);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal-pdf { 
    position: absolute;
    top: 15%;
    left: 20%;
    right: 20%;
    bottom: 15%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-modal-password { 
    position: absolute;
    top: 30%;
    left: 35%;
    right: 35%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-modal-audio { 
    position: absolute;
    top: 30%;
    left: 30%;
    right: 30%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-audioplayer {
    margin-bottom: "100px";
    height: auto;
}

.hak-modal-txt { 
    position: absolute;
    top: 15%;
    left: 20%;
    right: 20%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal-txt-overflow {
    overflow-y: scroll;
    height: 25em;
}

.hak-modal:focus { outline: none; }
.hak-modal-audio:focus { outline: none; }
.hak-modal-settings:focus { outline: none; }
.hak-modal-pdf:focus { outline: none; }
.hak-modal-txt:focus { outline: none; }
.hak-modal-password:focus { outline: none; }

.hak-modal-header {
    background-color: rgb(69, 76, 82);
    height: 35px;
    color: white;
    cursor: move;
}

.hak-form {
    padding: 1.5em;
    width: 80%;
}

.center  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hak-modal-settings { 
    position: absolute;
    top: 30%;
    left: 35%;
    right: 35%;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-settings-inside {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hak-settings-inside-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.error-message {
    color: red;
}
  
.hak-it-password-container {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .hak-it-password-inputBox {
    padding: 12px 20px;
    margin: 4px 4px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 35px !important;
  }

@media screen and (max-width:1082px) {
    .hak-modal-password, .hak-modal-audio, .hak-modal-txt, .hak-modal-pdf, .hak-modal-settings { 
        left: 10%;
        right: 10%;
    }

    .hak-modal {
        left: 5%;
        right: 5%;
    }
}