
.staff-content{
    margin-top:20rem;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size:50vw;
    overflow: auto;
}

.staff-title-svg{
    margin-bottom: 5rem;
    
}

.staff-content{
    font-size: larger;
    font-family: Futura;
}
.staff-frame{
    overflow: hidden;
}
.staff-image-container{
    height: 100%;
    position: absolute;
    width: 100%;
}
.staff-image {
    background-repeat: no-repeat;
    width: 100%;
    background-size: contain;
    padding: 0 1rem;
}
.staff-overlay{
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
    padding:1rem;
    height: 100%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    background-position-y: bottom;
    /* background-size: 18rem; */
}

.staff-text {
    font-size: 1vw;
}

@media screen and (max-width: 992px) {
    .staff-text {
        font-size: 0.6rem;
    }
}
@media screen and (max-width: 767px) {
    .staff-text {
        font-size: 2.5vw;
    }
}

@media screen and (max-width: 575px) {
    .staff-content {
        margin-top:1rem !important;
        background-image: none !important;
    }

    .staff-title-svg{
        margin-bottom: 3rem;
    }

    .staff-content{
        font-size: medium;
    }
    .staff-text {
        font-size: 5vw;
        margin-bottom:0.7rem
    }
}

.color-red {
    color:#881b1d
}
.font-weight-900 {
    font-weight: bolder!important;
}