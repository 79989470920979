
.sounds-content{
    padding-top:20rem;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: contain;
    overflow: hidden;
    font-size: larger;
    padding-bottom:5rem;
    font-family: Futura;
}

.sounds-title-svg{
    margin-bottom: 5rem;
}

.sounds-container{
    position: relative !important;
    height : 30vw !important;
}

.soundsphoto{
    height: auto;
    width: 45vw;
    background-size: contain;
}

.shound-photo-container{
    position: absolute !important;
}

.audio-controls-container{
    position: absolute;
}

.audio-controls{
    height: 5rem;
    width: 18rem;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: cover;
}

.soundsplaybutton{
    height: 5rem;
}

/* RHAP PLAYER START */

#soa-container .rhap_stacked .rhap_controls-section {
    margin-top: 0px ;
    visibility: hidden ;
}

#soa-container .rhap_main {
    height: 20px;
}

#soa-container #rhap_current-time {
    visibility: hidden;
    width: 0px;
}

#soa-container .rhap_container {
    background-color: transparent;
    box-shadow: none;
}

#soa-container .rhap_total-time {
    visibility: hidden;
    width: 0px ;
}

#soa-container .rhap_progress-indicator {
    background-color: rgb(150, 29, 29);
    border: 2px solid white;
    margin-top: 3px;
}

#soa-container .rhap_progress-filled {
    height: 10px;
    background-color: rgb(150, 29, 29);
}

#soa-container .rhap_progress-bar {
    height: 10px;
    background-color: rgb(223, 130, 49);
}
/* RHAP PLAYER END */

.soa-container {
    margin-top: -40px;
    width: 50%;
}

@media screen and (max-width: 575px) {
   
    .soa-container {
        margin-top: -115px;
        width: 90%;
    }

    .sounds-content {
        padding-top:1rem !important;
        background-image: none !important;
        font-size: medium;
        padding-bottom:20rem;
    }

    .sounds-title-svg{
        margin-bottom: 3rem;
    }

    .sound-photo-container{
        width:80vw;
    }

    .soundsphoto{
        width: 80vw;
    }
}