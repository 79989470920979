
.hak-icon {
    display: flex;
    height: 125px;
    padding: 0px;
}

.hak-icon-label {
    text-align: center;
    word-wrap: break-word;
}

.hak-icon img {
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.hak-icon-container {
    color: white;
}

.hak-icon-ul{
    margin-right: 0px !important;
}

.hak-desktop { 
    background-color: rgb(36, 41, 46);
    color: white;
    background-image: url('https://s3.us-east-1.amazonaws.com/cdn.huntakiller.com/summer-camp/icons/hakcamp-desktopbackground.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 50% 50%;
}

.rcw-conversation-container > .rcw-header {
    background-color: rgb(7,127,124) !important;
    padding: 5px 0 5px !important;
}

.rcw-client > .rcw-message-text{
    background-color: rgb(7,127,124) !important;
}

.rcw-launcher {
    background-color: rgb(7,127,124) !important;
}

.rcw-launcher > img {
    content:url("https://cdn.huntakiller.com/summer-camp/icons/hakcamp-chaticon-mobile-notext.svg");
}

.rcw-response > .rcw-message-text{
    color: black;
}

.hak-settings {
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 1em 1em !important;
    width: 100px;
    position: fixed;
    left: 0;
    color: #fff;
    background-color: transparent !important; 
    border-color: rgb(7,127,124) !important;
    cursor: pointer;
    z-index: 99;
}

.hak-poweroff {
    border-radius: 15px !important;
}

.rcw-close {
    width: 20px !important;
}

.rcw-conversation-container .rcw-close-button {
    display: block !important;
    background-color: transparent !important;
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
}

.hak-login-button {
    position: absolute;
    top: 80%;
    left: calc(50% - 75px);
    width: 150px;
    font-weight: bolder;
    font-size: 30px;
}

.rcw-close-launcher {
    width: 50px !important;
}

@media screen and (max-width:800px) {

    .rcw-widget-container {
        bottom: 0;
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: fixed;
        height: auto !important;
        right: 0;
        width: 100vw !important;
    }
    
    .rcw-conversation-container.active {
        opacity: 1 !important;
        transform: translateY(0) !important;
        transition: opacity 0.3s ease, transform 0.3s ease !important;
        height: 85vh !important;
    } 
}