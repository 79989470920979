#CampAshburn{
    position: absolute;
    top:0;
    z-index: 1001;
    overflow: hidden;
}

#Header {
    position: fixed;
    width: 100vw;
    z-index: 1;
}


@media screen and (max-width: 990px) {
    #Header {
        position: relative;
        width: 100vw;
    }
}

#Footer{
    height: fit-content;
}

.header-desktop{
    flex-direction: row;
    height: max-content;
    align-items: flex-start;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: cover;
    height:250px;
}

.header-mobile {
    flex-direction: column;
    height: max-content;
    align-items: center;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    height:250px;
}

.menu-icon-container{
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display:flex;
    flex-direction: row;
}

.floating-menu{
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1001;
}

.floating-menu-backdrop{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1001;
}

.menu-item-mobile{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size:700px;
    cursor: pointer;
}

.menu-divider{    height: 10px;
    width: 100%;
    margin-right: 25px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
}

.menu-link-text{
    width:80%;
    font-size: 30px;
}

.floating-menu-content{
    width: 70%;
    height: 100%;
    position: absolute;
    z-index: 1002;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding:2rem 0rem;
}

.close-icon{
    height: 3rem;
    width: 3rem;
    flex-shrink: 0;
}

.hamburger-menu-icon{
    height: 3rem;
    width: 3rem;
}

.header-logo-desktop {
    width: 190px;
    height:160px;
    margin: 0rem 0rem 0rem 4rem;
}

.header-logo-mobile{
    min-height: 4rem;
    width:50%;
}

.layout-body{
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: center;
}

.layout-body-content {
    /* height: inherit; */
    /* min-height: 100vh; */
    /* overflow: auto; */
    background-color: transparent;
}

.footer-desktop{
    flex-direction: column;
    height: max-content;
    align-items: flex-end;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    height:250px;
}
.footer-mobile{
    flex-direction: column;
    height: max-content;
    align-items: center;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    height:250px;
    padding-bottom: 1rem;
}

.footer-logo-desktop{
    width: 8rem;
    height: 5rem;
}

.footer-logo-mobile{
    width: 25%;
}

.menu-item {
    cursor: pointer;
    padding: 1rem;
    font-weight: bold;
    color:rgb(255, 255, 255);
    border-color: #fff;
    border-bottom-width: 2px;
    font-size: 2.2vw;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size:cover;
}

.menu-item.current {
    color: #2A3474;
    
}

.menu-item:hover{
    color: #2A3474;
}

.menu-item:last-child {
    border-color: #fff;
    border-bottom-width: 0px;

}

.Font-Trilby{
    font-family: trilby,serif;
}

.Font-Futura{
    font-family: Futura;
}

.pointer:hover {
    cursor :pointer;
}