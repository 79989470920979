
.memories-content{
    margin-top:20rem;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: contain;
    overflow: auto;
}

.memories-title-svg{
    margin-bottom: 5rem;
    
}

.wrapper{
    padding : 0rem 6rem;
    width: 100%;
}
.memoriessadiya {
    width:100%;
    height:50vw !important;
}

.dianne-quote-text{
    font-size: 1.3vw !important;
    padding: 0rem 1.5rem;
    color: orange;
}

.diannequote{
    height:16vw;
}

.memories-kayak-kids{
    width:100%;
    height:16vw !important;
}

.memories-ramil{
    width:100%;
    height:32vw !important;
}

.memories-ropes-kid{
    width:100%;
    height:20vw !important;
}

.memories-ropes-kid-overlay, .memories-brad-overlay, .memories-marcus-overlay{
    height: 100%;
    padding-bottom: 1rem;
}

.memories-ropes-kid-quote{
    font-size: 1.2vw;
    padding: 2rem 0rem 2rem 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    color: orange;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    width:60%;
}

.memories-brad-quote{
    font-size: 1.2vw;
    padding: 1.2rem 3rem 1.2rem 1.2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    color: orange;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    opacity: 0.8;
    width:75%;
}

.memories-marcus-quote{
    font-size: 1.3vw;
    padding: 2rem 2rem 1rem 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    color: orange;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    width:70%;
}

.memories-elliot{
    width:100%;
    height:20vw !important;
}

.memories-soccer-kids{
    width:100%;
    height:35vw !important;
}

.memories-jemma{
    width:100%;
    height:35vw !important;
}

.philomena-quote-text{
    font-size: 2.8vw !important;
}

.philomena-quote-text-name{
    font-size: 1.8vw !important;
}

.quote-philomena {
    width:100%;
    height:22vw !important;
    background-position-y: bottom !important;
    background-size: 35vw !important;
}

.memories-brad{
    width:100%;
    height:30vw !important;
}

.memories-hayley{
    width:100%;
    height:45vw !important;
}

.memories-adrienne{
    width:100%;
    height:50vw !important;
}

.memories-curtis{
    width:100%;
    height:30vw !important;
}

.adrienne-quote-text{
    font-size: 1.3vw !important;
    padding: 0rem 2.2rem;
    color: orange;
}

.adriennequote{
    height:20vw;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
}

.memories-running-kids{
    width:100%;
    height:25vw !important;
}

.memories-marshmellow-kids{
    width:100%;
    height:50vw !important;
}

@media screen and (max-width: 575px) {
    .memories-content {
        margin-top:1rem !important;
        background-image: none !important;
    }

    .memories-title-svg{
        margin-bottom: 1rem;
        
    }

    .wrapper{
        padding : 0rem 0rem;
        width: 100%;
    }
    
    .dianne-quote-text{
        font-size: 5vw !important;
        color: orange;
    }

    .memoriessadiya {
        width:100%;
        height:90vw;
        padding: 1rem !important;
    }

    .diannequote{
        height:30vh;
        width:100%;
        background-repeat: no-repeat;
        background-size:cover;
        word-wrap: normal;
        font-size: 1.8rem;
        padding: 0.5rem !important;
        text-align: center;
    }   



    .memories-kayak-kids{
        width:100%;
        height:30vh !important;
    }

    .memories-ramil{
        width:100%;
        height:30vh !important;
    }
        
    .memories-ropes-kid{
        width:100%;
        height:30vh !important;
    }

        
    .memories-ropes-kid-quote{
        font-size: 3vw;
        padding: 1rem 0rem 1rem 1rem;
    }


    .memories-brad-quote{
        font-size: 3vw;
        padding: 1rem 3rem 1rem 1rem;
        width:50% !important;
    }

    .memories-marcus-quote{
        font-size: 3vw;
        padding: 1rem 0rem 1rem 1rem;
    }

    .memories-elliot{
        width:100%;
        height:40vh !important;
    }

    .memories-soccer-kids{
        width:100%;
        height:40vh !important;
    }

    .memories-jemma{
        width:100%;
        height:45vh !important;
    }

    .philomena-quote-text{
        font-size: 8vw !important;
    }

    .philomena-quote-text-name{
        font-size: 5vw !important;
    }

    .quote-philomena {
        width:100%;
        height:40vh !important;
        background-position-y: bottom !important;
        background-size: 520px !important;
    }

    .memories-brad{
        width:100%;
        height:45vh !important;
    }


    .memories-hayley{
        width:100%;
        height:60vh !important;
    }

    .memories-adrienne{
        width:100%;
        height:60vh !important;
    }


    .memories-curtis{
        width:100%;
        height:60vh !important;
    }

    .adrienne-quote-text{
        font-size: 5vw !important;
        padding: 0rem 2.2rem;
        color: orange;
    }

    .adriennequote{
        height:47vh;
    }


    .memories-running-kids{
        width:100%;
        height:30vh !important;
    }

    .memories-marshmellow-kids{
        width:100%;
        height:30vh !important;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        margin-bottom:1rem;
    }



}

.media-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    margin-bottom:1rem;
}


.color-red {
    color:#881b1d
}
